import { FetchQueryOptions } from '@tanstack/react-query';
import { AlchemyProvider, Contract } from 'ethers';
import { queryClient } from '../query';

let provider = new AlchemyProvider('matic', 'jKHL8FBDC9OR14KUb_n-J0_5KoF9hjDo');

export async function getComo(
  artist: 'artms' | 'tripleS',
  address: string,
): Promise<bigint> {
  let contractAddress = {
    artms: '0x8254D8D2903B20187cBC4Dd833d49cECc219F32E',
    tripleS: '0x58AeABfE2D9780c1bFcB713Bf5598261b15dB6e5',
  };
  let contractAbi = [
    'function balanceOf(address tokenHolder) view returns (uint256)',
  ];
  let contract = new Contract(contractAddress[artist], contractAbi, provider);
  let balance = await contract.balanceOf(address);
  let como = balance / 1000000000000000000n;
  return como;
}

export function comoQuery(
  artist: 'tripleS' | 'artms',
  address: string,
): FetchQueryOptions<bigint> {
  return {
    queryKey: ['como', artist, address],
    queryFn: () => getComo(artist, address),
  };
}

export async function getTimestamps(
  blockNumbers: number[],
): Promise<{ [blockNumber: number]: number }> {
  let entries = (
    await Promise.all(
      Array.from(blockNumbers).map(async blockNumber => [
        blockNumber,
        (await provider.getBlock(blockNumber))?.timestamp,
      ]),
    )
  ).filter(([, timestamp]) => !!timestamp);
  return Object.fromEntries(entries);
}

export function blockTimestampQuery(
  blockNumber: number,
): FetchQueryOptions<number> {
  return {
    queryKey: ['blockTimestamp', blockNumber],
    queryFn: async () => {
      let block = await provider.getBlock(blockNumber);
      if (!block?.timestamp) {
        throw new Error(`Could not fetch timestamp for block ${blockNumber}`);
      }
      return block.timestamp;
    },
    staleTime: Infinity,
  };
}

export function batchBlockTimestampsQuery(
  blockNumbers: number[],
): FetchQueryOptions<{ [blockNumber: number]: number }> {
  return {
    queryKey: ['batchBlockTimestamps', blockNumbers],
    queryFn: async () => {
      let unknownBlocks = blockNumbers.filter(
        block => !queryClient.getQueryData(['blockTimestamp', block]),
      );

      if (unknownBlocks.length === 0) {
        return Object.fromEntries(
          blockNumbers.map(block => [
            block,
            queryClient.getQueryData(['blockTimestamp', block]) as number,
          ]),
        );
      }

      let newTimestamps = await getTimestamps(unknownBlocks);

      for (let [block, timestamp] of Object.entries(newTimestamps)) {
        queryClient.setQueryData(['blockTimestamp', Number(block)], timestamp);
      }
      return Object.fromEntries(
        blockNumbers.map(blockNumber => [
          blockNumber,
          newTimestamps[blockNumber] ||
            (queryClient.getQueryData([
              'blockTimestamp',
              blockNumber,
            ]) as number),
        ]),
      );
    },
    staleTime: Infinity,
  };
}
