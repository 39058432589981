import { Fragment, SetStateAction, useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  Checkbox,
  Divider,
  ScrollShadow,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Spinner,
} from '@nextui-org/react';

import { motion, AnimatePresence } from 'framer-motion';
import { TRANSITION_VARIANTS } from '@nextui-org/framer-utils';
import { ChevronDownIcon, ChevronUpIcon } from '@nextui-org/shared-icons';

import {
  AlchemyProvider,
  Contract,
  Wallet,
  isError as isEthersError,
} from 'ethers';
import { captureException } from '@sentry/react';

import { ErrorIcon, CheckIcon } from '../icons';
import { CosmoNicknameAutocomplete } from '../components/cosmo';

import { cosmoClient } from '../cosmo';
import { getWalletMnemonic } from '../ramper';
import { useCurrentAccount } from '../credentials';
import { getShortCollectionId } from '../utils';
import {
  useIsLoading,
  useSelectedObjekts,
  useSendError,
  useSendObjektsActions,
  useTxStates,
} from '../stores/objekts';

export function SelectedObjektsCard({ isMyAccount }: { isMyAccount: boolean }) {
  let currentAccount = useCurrentAccount();
  let ramperWalletSecrets = currentAccount?.ramperWalletSecrets;
  let selectedObjekts = useSelectedObjekts();
  let txStates = useTxStates();
  let isLoading = useIsLoading();
  let error = useSendError();
  let {
    selectObjekt,
    unselectObjekt,
    clearSelectedObjekts,
    setTxState,
    setIsLoading,
    setError,
  } = useSendObjektsActions();

  let [recipientNickname, setRecipientNickname_] = useState('');
  function setRecipientNickname(newRecipientNickname: SetStateAction<string>) {
    setRecipientNickname_(newRecipientNickname);
    setError('');
  }

  async function onSendButtonPress() {
    if (!ramperWalletSecrets) {
      return;
    }
    try {
      setIsLoading(true);
      setError('');
      let provider = new AlchemyProvider(
        'matic',
        'jKHL8FBDC9OR14KUb_n-J0_5KoF9hjDo',
      );
      let feeData = await provider.getFeeData();

      let recipientProfile =
        await cosmoClient.getProfileByNickname(recipientNickname);

      let contractAddresses = {
        artms: '0x0fB69F54bA90f17578a59823E09e5a1f8F3FA200',
        tripleS: '0xA4B37bE40F7b231Ee9574c4b16b7DDb7EAcDC99B',
      };
      let contractAbi = [
        'function transferFrom(address from, address to, uint256 tokenId) public',
      ];
      let contracts = {
        artms: new Contract(contractAddresses['artms'], contractAbi, provider),
        tripleS: new Contract(
          contractAddresses['tripleS'],
          contractAbi,
          provider,
        ),
      };
      let { version, dek, encryptedKey } = ramperWalletSecrets;
      let wallet = Wallet.fromPhrase(
        await getWalletMnemonic(version, dek, encryptedKey),
        provider,
      );

      let senderAddress = wallet.address;
      let recipientAddress = recipientProfile.address;

      let nonce = await wallet.getNonce();
      let hasError = false;

      for (let i = 0; i < selectedObjekts.length; i += 8) {
        let selectedObjektsBatch = selectedObjekts.slice(i, i + 8);
        await Promise.all(
          selectedObjektsBatch.map(async (selectedObjekt, j) => {
            let index = i + j;
            try {
              let artist = (selectedObjekt.artists ?? ['tripleS'])[0] as
                | 'artms'
                | 'tripleS';
              let tokenId = selectedObjekt.tokenId;
              let contract = contracts[artist];
              let contractAddress = contractAddresses[artist];
              let txData = contract.interface.encodeFunctionData(
                'transferFrom',
                [senderAddress, recipientAddress, tokenId],
              );
              let tx = {
                nonce: nonce++,
                // gasLimit: 300000,
                gasLimit: await wallet.estimateGas({
                  to: contractAddress,
                  data: txData,
                }),
                maxFeePerGas: (feeData.maxFeePerGas! * 160n) / 100n,
                maxPriorityFeePerGas:
                  (feeData.maxPriorityFeePerGas! * 160n) / 100n,
                to: contractAddress,
                data: txData,
              };
              setTxState(index, 'sending', '');
              let txResult = await wallet.sendTransaction(tx);
              let txReceipt = await txResult.wait();
              console.log(txReceipt);
              setTxState(index, 'success', '');
            } catch (error) {
              hasError = true;
              console.error(error);
              captureException(error);
              let message: string;
              if (isEthersError(error, 'CALL_EXCEPTION')) {
                message = error.reason ?? 'A blockchain error has occurred.';
                if (
                  error.reason ===
                  'ERC721: transfer caller is not owner nor approved'
                ) {
                  message = 'You have already sent this objekt.';
                }
              } else if (isEthersError(error, 'TRANSACTION_REPLACED')) {
                message =
                  'Transaction was replaced\nThis usually means you have sent other objekts in a different method.';
              } else if (error instanceof Error) {
                message = error.message;
              } else {
                message = 'An unknown error has occurred.';
              }
              setTxState(index, 'error', message);
            }
          }),
        );
      }
      if (!hasError) {
        clearSelectedObjekts();
      }
    } catch (error) {
      console.error(error);
      if (error instanceof Error) {
        setError(error.message);
      }
    } finally {
      setIsLoading(false);
    }
  }

  let [isCardOpen, setIsCardOpen] = useState(true);

  return (
    <Card className='max-h-[60vh]'>
      <CardHeader>
        <div className='flex-1 font-bold'>
          {selectedObjekts.length} Selected Objekt
          {selectedObjekts.length > 1 ? 's' : ''}
        </div>
        <button
          className='appearance-none select-none p-2 text-foreground-500 rounded-full hover:bg-default-100 active:bg-default-200 tap-highlight-transparent data-[focus-visible=true]:z-10 data-[focus-visible=true]:outline-2 data-[focus-visible=true]:outline-focus data-[focus-visible=true]:outline-offset-2'
          onClick={() => setIsCardOpen(!isCardOpen)}
        >
          {isCardOpen ? <ChevronDownIcon /> : <ChevronUpIcon />}
        </button>
      </CardHeader>
      {isCardOpen ? (
        <>
          <Divider />
          <ScrollShadow>
            <CardBody as={motion.div} layout>
              <AnimatePresence initial={false}>
                {selectedObjekts.map((objekt, i) => (
                  <motion.div
                    key={objekt.tokenId}
                    layout
                    variants={TRANSITION_VARIANTS.collapse}
                    animate='enter'
                    exit='exit'
                    initial='exit'
                  >
                    <div className='flex h-8'>
                      <Checkbox
                        className='max-w-none flex-1'
                        isSelected={selectedObjekts.includes(objekt)}
                        onValueChange={isSelected => {
                          if (isSelected) {
                            selectObjekt(objekt);
                          } else {
                            unselectObjekt(objekt);
                          }
                        }}
                      >
                        {getShortCollectionId(objekt)} #{objekt.objektNo}
                      </Checkbox>
                      {txStates[i].status === 'sending' && (
                        <Spinner size='sm' className='w-8 h-8' />
                      )}
                      {txStates[i].status === 'error' && (
                        <Popover>
                          <PopoverTrigger>
                            <Button
                              isIconOnly
                              size='sm'
                              variant='light'
                              color='warning'
                              aria-label='View error'
                            >
                              <ErrorIcon className='w-6 h-6' />
                            </Button>
                          </PopoverTrigger>
                          <PopoverContent>
                            <p className='max-w-[20rem] text-danger break-words'>
                              {txStates[i].error
                                .split('\n')
                                .map((line, index) => (
                                  <Fragment key={index}>
                                    {index !== 0 && <br />}
                                    {line}
                                  </Fragment>
                                ))}
                            </p>
                          </PopoverContent>
                        </Popover>
                      )}
                      {txStates[i].status === 'success' && (
                        <CheckIcon className='text-success w-8 h-8 p-1' />
                      )}
                    </div>
                  </motion.div>
                ))}
              </AnimatePresence>
            </CardBody>
          </ScrollShadow>
          {isMyAccount ? (
            <>
              <Divider />
              <CardBody className='shrink-0'>
                <CosmoNicknameAutocomplete
                  label='COSMO ID'
                  placeholder='Recipient COSMO ID'
                  nickname={recipientNickname}
                  setNickname={setRecipientNickname}
                  isInvalid={!!error}
                  errorMessage={error}
                />
              </CardBody>
              <Divider />
              <CardFooter className='shrink-0'>
                <Button
                  color='primary'
                  onClick={onSendButtonPress}
                  isLoading={isLoading}
                >
                  Send
                </Button>
              </CardFooter>
            </>
          ) : null}
        </>
      ) : null}
    </Card>
  );
}
