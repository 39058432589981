import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import {
  LoaderFunctionArgs,
  useLoaderData,
  useParams,
  useMatch,
  useNavigate,
  useOutletContext,
  Outlet,
  NavLink,
  redirect,
} from 'react-router-dom';
import {
  User,
  Chip,
  Divider,
  Select,
  SelectItem,
  Radio,
  RadioGroup,
  Spinner,
  Tabs,
  Tab,
  Modal,
  ModalContent,
  useDisclosure,
  Skeleton,
} from '@nextui-org/react';

import { motion, AnimatePresence } from 'framer-motion';
import { TRANSITION_VARIANTS } from '@nextui-org/framer-utils';
import { useWindowVirtualizer } from '@tanstack/react-virtual';

import { Search } from 'js-search';
import { AlchemyProvider } from 'ethers';

import { Artist, Objekt, SearchedProfile } from '../cosmo';
import { useAccounts, useCurrentAccount } from '../credentials';
import { Link } from './root';

import { ObjektModal } from '../components/objekt-modal';
import { ObjektFilters, ObjektList } from '../components/objekt-list';
import { SelectedObjektsCard } from '../components/objekt-card';
import { ComoArtmsIcon, ComoTripleSIcon, FilterIcon } from '../icons';
import {
  useMediaQuery,
  useSearchParamState,
  useSearchParamStates,
} from '../hooks';
import { filterObjekts, getShortCollectionId } from '../utils';
import { useSelectedObjekts, useSendObjektsActions } from '../stores/objekts';
import { useQueries, useQuery, useSuspenseQuery } from '@tanstack/react-query';
import { queryClient } from '../query';
import {
  allObjektsQuery,
  artistQuery,
  allOwnedObjektsQuery,
  searchUsersQuery,
  ownedObjektsQuery,
  userWantsListQuery,
  userTransfersQuery,
  batchNicknamesQuery,
} from '../queries/cosmo';
import { batchBlockTimestampsQuery, comoQuery } from '../queries/polygon';

let provider = new AlchemyProvider('matic', 'jKHL8FBDC9OR14KUb_n-J0_5KoF9hjDo');

export async function loader({ params }: LoaderFunctionArgs) {
  let { userId } = params as { userId?: string };
  let address: string;
  let profileImageUrl = '';
  if (!userId) {
    return {
      address: undefined,
      profileImageUrl: '',
      como: [0n, 0n],
    };
  } else if (userId.startsWith('@')) {
    let nickname = userId.substring(1);
    let profile: SearchedProfile;
    let { results } = await queryClient.fetchQuery(searchUsersQuery(nickname));
    profile = results[0];
    address = profile.address;
    profileImageUrl = profile.profileImageUrl;
    if (profile.profile.length) {
      profileImageUrl = profile.profile[0].image.original;
    }
  } else if (userId.startsWith('0x')) {
    let nicknames = await getNicknames([userId]);
    if (nicknames[userId]) {
      userId = `@${nicknames[userId]}`;
      throw redirect(`/${userId}`);
    }
    address = userId;
  } else {
    throw new Error('unexpected userId given');
  }
  let [comoTripleS, comoArtms] = await Promise.all([
    queryClient.prefetchQuery(comoQuery('tripleS', address)),
    queryClient.prefetchQuery(comoQuery('artms', address)),
  ]);
  return {
    address,
    profileImageUrl,
    como: [comoTripleS, comoArtms],
  };
}

export function useOwnedObjekts(
  address: string,
  enabled: boolean = true,
): Objekt[] {
  let { data: firstPage } = useQuery({
    ...ownedObjektsQuery(address, 0),
    enabled: enabled,
  });

  let queries: (ReturnType<typeof ownedObjektsQuery> & {
    enabled: boolean;
  })[] = [];
  if (firstPage?.hasNext) {
    let nextStartAfter = parseInt(firstPage.nextStartAfter);
    for (
      let startAfter = nextStartAfter;
      startAfter < firstPage.total;
      startAfter += nextStartAfter
    ) {
      queries.push({
        ...ownedObjektsQuery(address, startAfter),
        enabled: enabled && !!firstPage,
      });
    }
  }

  let queryResults = useQueries({ queries });
  let pages = queryResults.map(({ data }) => data);
  let objekts = [firstPage, ...pages]
    .filter((page): page is NonNullable<typeof page> => !!page)
    .flatMap(({ objekts }) => objekts);
  return objekts;
}

export function useMyOwnedObjekts() {
  let currentAccount = useCurrentAccount();
  let address = currentAccount?.profile.address;
  let { data: objekts } = useQuery({
    ...allOwnedObjektsQuery(address!),
    enabled: !!address,
  });
  return objekts ?? [];
}

export async function getNicknames(
  addresses: string[],
): Promise<{ [address: string]: string }> {
  if (addresses.length == 0) {
    return {};
  }
  let promises: Promise<[string, string][]>[] = [];
  for (let i = 0; i < addresses.length; i += 150) {
    let addressesBatch = addresses.slice(i, i + 150);
    promises.push(
      (async () => {
        let response = await fetch(
          `https://search.goranmoomin.dev/user/v1/by-address/${addressesBatch.join(
            ',',
          )}`,
        );
        let json = (await response.json()) as {
          nickname: string;
          address: string;
          profileImageUrl: string;
        }[];
        return json.map(({ address, nickname }) => [address, nickname]);
      })(),
    );
  }
  let entries = (await Promise.all(promises)).flat();
  return Object.fromEntries(entries);
}

export async function getTimestamps(
  blockNumbers: number[],
): Promise<{ [blockNumber: number]: number }> {
  let entries = (
    await Promise.all(
      Array.from(blockNumbers).map(async blockNumber => [
        blockNumber,
        (await provider.getBlock(blockNumber))?.timestamp,
      ]),
    )
  ).filter(([, timestamp]) => !!timestamp);
  return Object.fromEntries(entries);
}

export function TradesTab({ address }: { address: string }) {
  let { data: transfers } = useQuery(userTransfersQuery(address));
  transfers ??= [];
  let addresses = new Set<string>(
    transfers
      .map(t => t.from)
      .filter(
        address => address !== '0x0000000000000000000000000000000000000000',
      )
      .concat(transfers.map(t => t.to)),
  );
  let { data: nicknames } = useQuery(
    batchNicknamesQuery(Array.from(addresses)),
  );
  nicknames ??= {};
  let { data: timestamps } = useQuery(
    batchBlockTimestampsQuery(
      Array.from(new Set(transfers.map(t => t.blockNumber))),
    ),
  );
  timestamps ??= {};

  let [displayOption, setDisplayOption] = useState<
    'all' | 'mints' | 'froms' | 'tos'
  >('all');

  let filteredTransfers = useMemo(() => {
    switch (displayOption) {
      case 'mints':
        return transfers.filter(
          t => t.from === '0x0000000000000000000000000000000000000000',
        );
      case 'froms':
        return transfers.filter(t => t.from === address);
      case 'tos':
        return transfers.filter(t => t.to === address);
      default:
        return transfers;
    }
  }, [transfers, displayOption, address]);

  let parentRef = useRef<HTMLDivElement>(null);
  let parentOffsetRef = useRef(0);
  useLayoutEffect(() => {
    parentOffsetRef.current = parentRef.current?.offsetTop ?? 0;
  }, []);

  let virtualizer = useWindowVirtualizer({
    count: filteredTransfers.length,
    estimateSize: () => 40,
    scrollMargin: parentOffsetRef.current,
  });
  let virtualItems = virtualizer.getVirtualItems();

  return (
    <div ref={parentRef}>
      <div className='flex flex-col sm:flex-row w-full gap-4 md:max-w-[46rem] sm:px-4 mb-4 mx-auto'>
        <div className='flex flex-row gap-2 grow items-center order-2 sm:order-1 text-lg font-medium'>
          {filteredTransfers.length}{' '}
          {displayOption === 'all'
            ? filteredTransfers.length === 1
              ? 'Trade'
              : 'Trades'
            : displayOption === 'mints'
              ? filteredTransfers.length === 1
                ? 'Objekt minted'
                : 'Objekts minted'
              : displayOption === 'froms'
                ? filteredTransfers.length === 1
                  ? 'Objekt sent'
                  : 'Objekts sent'
                : filteredTransfers.length === 1
                  ? 'Objekt received'
                  : 'Objekts received'}
        </div>
        <Select
          className='w-[auto] order-1 sm:order-2 flex-1'
          label='Display'
          selectedKeys={[displayOption]}
          onSelectionChange={keys =>
            setDisplayOption(Array.from(keys)[0] as typeof displayOption)
          }
        >
          <SelectItem key='all' value='all'>
            All Trades
          </SelectItem>
          <SelectItem key='mints' value='mints'>
            Mints
          </SelectItem>
          <SelectItem key='froms' value='froms'>
            Sent
          </SelectItem>
          <SelectItem key='tos' value='tos'>
            Received
          </SelectItem>
        </Select>
      </div>
      <div style={{ height: virtualizer.getTotalSize() }} className='relative'>
        <div
          className='absolute top-0 inset-x-0'
          style={{
            transform: `translateY(${
              (virtualItems[0]?.start ?? 0) - virtualizer.options.scrollMargin
            }px)`,
          }}
        >
          {virtualItems.map(item => {
            let transfer = filteredTransfers[item.index];
            return (
              <div
                key={item.index}
                data-index={item.index}
                ref={virtualizer.measureElement}
              >
                <div className='flex flex-col gap-1 md:flex-row md:gap-4 mx-auto py-2 items-center justify-center text-center'>
                  <div className='md:basis-48'>
                    {timestamps[transfer.blockNumber] ? (
                      new Date(
                        timestamps[transfer.blockNumber] * 1000,
                      ).toLocaleString('en-US')
                    ) : (
                      <Spinner size='sm' />
                    )}
                  </div>
                  <Divider
                    orientation='vertical'
                    className='hidden md:block h-auto self-stretch'
                  />
                  <div className='md:basis-64'>
                    {'objektNo' in transfer.objekt
                      ? `${getShortCollectionId(transfer.objekt)} #${
                          transfer.objekt.objektNo
                        }`
                      : ''}
                  </div>
                  <Divider
                    orientation='vertical'
                    className='hidden md:block h-auto self-stretch'
                  />
                  <div className='flex gap-2 md:basis-48 items-center justify-center'>
                    {transfer.to === address ? (
                      <Chip size='sm' color='primary'>
                        From
                      </Chip>
                    ) : (
                      <Chip size='sm' color='secondary'>
                        To
                      </Chip>
                    )}
                    {transfer.to === address ? (
                      transfer.from ===
                      '0x0000000000000000000000000000000000000000' ? (
                        'COSMO'
                      ) : nicknames[transfer.from] ? (
                        <Link
                          className='inline'
                          to={`/@${nicknames[transfer.from]}`}
                        >
                          {nicknames[transfer.from]}
                        </Link>
                      ) : (
                        <Link className='inline' to={`/${transfer.from}`}>
                          {transfer.from}
                        </Link>
                      )
                    ) : nicknames[transfer.to] ? (
                      <Link
                        className='inline'
                        to={`/@${nicknames[transfer.to]}`}
                      >
                        {nicknames[transfer.to]}
                      </Link>
                    ) : (
                      <Link className='inline' to={`/${transfer.to}`}>
                        {transfer.to}
                      </Link>
                    )}
                  </div>
                </div>
                <Divider className='md:max-w-[46rem] mx-auto' />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

function ObjektsTab({
  objekts,
  artists,
  address,
  userId,
}: {
  objekts: Objekt[];
  artists: Artist[];
  address: string;
  userId: string;
}) {
  let currentAccount = useCurrentAccount();
  let isMyAccount = !userId || currentAccount?.profile.address === address;

  let ownedObjekts = useOwnedObjekts(address);
  let myOwnedObjekts = useMyOwnedObjekts();

  let { data: wantsList } = useQuery(userWantsListQuery(address));
  wantsList ??= [];
  let { data: myWantsList } = useQuery({
    ...userWantsListQuery(currentAccount?.profile.address!),
    enabled: !!currentAccount,
  });
  console.log('myWantsList', myWantsList);
  myWantsList ??= [];
  if (isMyAccount) {
    wantsList = myWantsList;
  }

  let search = useMemo(() => {
    let search = new Search('collectionId');
    search.addIndex('artists');
    search.addIndex('season');
    search.addIndex('class');
    search.addIndex('member');
    search.addIndex('collectionId');
    // Ugh! This is a hack, read getAllObjekts to see why this works.
    search.addIndex('shortCollectionId');
    search.addDocuments(objekts);
    return search;
  }, [objekts]);

  let artistOptions = useMemo(
    () =>
      Array.from(
        new Set(objekts.flatMap(objekt => objekt.artists ?? ['tripleS'])),
      ),
    [objekts],
  );
  let [artistName, setArtistName] = useSearchParamState('artist', 'tripleS');
  let artist = artists.find(artist => artist.name === artistName);

  objekts = useMemo(
    () =>
      objekts
        .filter(objekt => (objekt.artists ?? ['tripleS']).includes(artistName))
        .sort((a, b) => {
          let aIndex =
            artist?.members.findIndex(member => member.name === a.member) ?? -1;
          let bIndex =
            artist?.members.findIndex(member => member.name === b.member) ?? -1;
          if (aIndex === -1) {
            return 1;
          } else if (bIndex === -1) {
            return -1;
          } else {
            return aIndex - bIndex;
          }
        }),
    [objekts, artistName, artist],
  );
  let memberOptions = useMemo(
    () =>
      Array.from(new Set(objekts.map(objekt => objekt.member))).sort((a, b) => {
        let aIndex =
          artist?.members.findIndex(member => member.name === a) ?? -1;
        let bIndex =
          artist?.members.findIndex(member => member.name === b) ?? -1;
        if (aIndex === -1) {
          return 1;
        } else if (bIndex === -1) {
          return -1;
        } else {
          return aIndex - bIndex;
        }
      }),
    [objekts],
  );
  let seasonOptions = useMemo(
    () => Array.from(new Set(objekts.map(objekt => objekt.season))),
    [objekts],
  );
  let classOptions = useMemo(
    () => Array.from(new Set(objekts.map(objekt => objekt.class))),
    [objekts],
  );

  let [members, setMembers] = useSearchParamStates('members');
  let [seasons, setSeasons] = useSearchParamStates('seasons');
  let [classes, setClasses] = useSearchParamStates('classes');
  let [query, setQuery] = useSearchParamState('q', '', { replace: true });
  // TODO: This is a hack to make the filter option default to 'owned' when the user is logged in.
  let { isLoading: isAccountsLoading } = useAccounts();
  let isLoggedIn = !!currentAccount || isAccountsLoading;
  let [filterOption, setFilterOption] = useState<
    'all' | 'owned' | 'transferable'
  >(userId || isLoggedIn ? 'owned' : 'all');

  let isUserWantsList = !!useMatch(':userId/wants');
  let isMyWantsList = !!useMatch('/wants');
  let isWantsList = isUserWantsList || isMyWantsList;
  let isUserHavesList = !!useMatch(':userId/haves');
  let isMyHavesList = !!useMatch('/haves');
  let isHavesList = isUserHavesList || isMyHavesList;
  let selectedList: 'all' | 'wants' | 'haves' = isWantsList
    ? 'wants'
    : isHavesList
      ? 'haves'
      : 'all';
  let navigate = useNavigate();
  function setSelectedList(selectedList: 'all' | 'wants' | 'haves') {
    switch (selectedList) {
      case 'all':
        userId ? navigate(`/${userId}`) : navigate('/');
        break;
      case 'wants':
        userId ? navigate(`/${userId}/wants`) : navigate('/wants');
        break;
      case 'haves':
        userId ? navigate(`/${userId}/haves`) : navigate('/haves');
        break;
    }
  }

  objekts = useMemo(() => {
    objekts = filterObjekts(
      objekts,
      members,
      seasons,
      classes,
      isMyAccount && selectedList === 'wants' ? 'all' : filterOption,
      selectedList === 'wants' ? myOwnedObjekts : ownedObjekts,
    );
    if (selectedList === 'wants') {
      // the other user's wants, objekts displayed should be what I have
      objekts = objekts.filter(objekt =>
        wantsList.includes(objekt.collectionId),
      );
    } else if (selectedList === 'haves') {
      // My wants, objekts displayed should be what the other user has
      objekts = objekts.filter(objekt =>
        myWantsList.includes(objekt.collectionId),
      );
    }
    return objekts;
  }, [
    objekts,
    members,
    seasons,
    classes,
    filterOption,
    ownedObjekts,
    selectedList,
    wantsList,
    myWantsList,
    isMyAccount,
  ]);

  // query changes frequently, so should be memoized separately.
  objekts = useMemo(() => {
    if (query) {
      objekts = (search.search(query) as Objekt[]).filter(objekt =>
        objekts.includes(objekt),
      );
    }
    return objekts;
  }, [objekts, search, query]);

  let selectedObjekts = useSelectedObjekts();
  let { clearSelectedObjekts } = useSendObjektsActions();
  useEffect(() => {
    // This is a hack to clear the selected objekts when we switch to a different user.
    clearSelectedObjekts();
  }, [address]);

  let [selectedObjektForModal, setSelectedObjektForModal] =
    useState<Objekt | null>(null);
  let {
    isOpen: isModalOpen,
    onOpen: onModalOpen,
    onOpenChange: onModalOpenChange,
  } = useDisclosure();

  function onObjektPress(objekt: Objekt) {
    setSelectedObjektForModal(objekt);
    onModalOpen();
  }

  let isMobile = !useMediaQuery('(min-width: 640px)');

  let [isFiltersOpen, setIsFiltersOpen] = useState(false);
  let isFiltersActive =
    members.length || seasons.length || classes.length || query;

  return (
    <div className='flex flex-col gap-4'>
      <div>
        {isMobile ? (
          <Chip
            as='button'
            color='primary'
            variant={isFiltersActive || isFiltersOpen ? 'solid' : 'flat'}
            startContent={<FilterIcon className='w-4 h-4 ml-1' />}
            onClick={() => setIsFiltersOpen(!isFiltersOpen)}
          >
            Filters
          </Chip>
        ) : null}
        <AnimatePresence>
          {!isMobile || (isFiltersOpen && isMobile) ? (
            <motion.div
              variants={TRANSITION_VARIANTS.collapse}
              animate='enter'
              exit='exit'
              initial='exit'
            >
              <div className={isMobile ? 'pt-4' : ''}>
                <ObjektFilters
                  artist={artistName}
                  members={members}
                  seasons={seasons}
                  classes={classes}
                  artistOptions={artistOptions}
                  memberOptions={memberOptions}
                  seasonOptions={seasonOptions}
                  classOptions={classOptions}
                  setArtist={setArtistName}
                  setMembers={setMembers}
                  setSeasons={setSeasons}
                  setClasses={setClasses}
                  query={query}
                  setQuery={setQuery}
                />
              </div>
            </motion.div>
          ) : null}
        </AnimatePresence>
      </div>
      <div className='flex flex-col w-full sm:flex-row gap-4'>
        {!isMyAccount || selectedList === 'all' ? (
          <RadioGroup
            label='Display options'
            orientation='horizontal'
            value={filterOption}
            onValueChange={value =>
              setFilterOption(value as 'all' | 'owned' | 'transferable')
            }
            className='grow order-2 sm:order-1'
          >
            <Radio value='all'>All</Radio>
            <Radio value='owned'>
              Owned
              {selectedList === 'wants'
                ? ' by me'
                : selectedList === 'haves'
                  ? ` by them`
                  : ''}
            </Radio>
            <Radio value='transferable'>
              Sendable
              {selectedList === 'wants'
                ? ' by me'
                : selectedList === 'haves'
                  ? ` by them`
                  : ''}
            </Radio>
          </RadioGroup>
        ) : (
          <div className='flex flex-row gap-2 grow order-2 sm:order-1' />
        )}
        <Select
          label='Display List'
          selectedKeys={[selectedList]}
          onSelectionChange={selectedKeys =>
            setSelectedList(
              Array.from(selectedKeys as Set<'all' | 'wants' | 'haves'>)[0],
            )
          }
          className='w-[auto] sm:max-w-xs order-1 sm:order-2 flex-1'
        >
          {[
            <SelectItem key='all' value='all'>
              All Objekts
            </SelectItem>,
            <SelectItem key='wants' value='wants'>
              {!userId || isMyAccount ? 'My Wants' : 'Their Wants'}
            </SelectItem>,
            ...(userId && !isMyAccount
              ? [
                  <SelectItem key='haves' value='haves'>
                    My Wants
                  </SelectItem>,
                ]
              : []),
          ]}
        </Select>
      </div>
      <div className='text-medium font-medium'>
        {query && `Search results for: ${query}`}
      </div>
      <Modal
        size='3xl'
        className='mb-1'
        scrollBehavior='outside'
        isOpen={isModalOpen}
        onOpenChange={onModalOpenChange}
      >
        <ModalContent>
          {onClose => (
            <ObjektModal
              objekt={selectedObjektForModal!}
              onClose={onClose}
              ownedObjekts={ownedObjekts
                .filter(
                  objekt =>
                    objekt.collectionId ===
                    selectedObjektForModal!.collectionId,
                )
                // toSorted is from ES2023
                // .toSorted((a, b) => a.objektNo - b.objektNo)
                // since .filter returns a new array, an in-place sort works.
                .sort((a, b) => a.objektNo - b.objektNo)}
            />
          )}
        </ModalContent>
      </Modal>
      {objekts.length === 0 ? (
        <div className='flex flex-col gap-2 items-center'>
          <div className='text-small font-medium text-foreground-500'>
            {selectedList === 'haves'
              ? 'Mark any objekt as Want to share your Want list.'
              : selectedList === 'wants'
                ? 'No objekts matches.'
                : 'No objekt matches your query.'}
          </div>
          {selectedList === 'haves' && (
            <img
              className='max-w-96'
              src={
                new URL('../resources/add-to-list.png', import.meta.url).href
              }
            />
          )}
        </div>
      ) : null}
      <ObjektList
        objekts={objekts}
        ownedObjekts={ownedObjekts}
        onObjektPress={onObjektPress}
        wantsList={myWantsList}
        onListChange={() => {
          if (currentAccount) {
            queryClient.invalidateQueries({
              queryKey: ['userDetails', currentAccount.profile.address],
              refetchType: 'all',
            });
          }
        }}
      />
      <AnimatePresence>
        {selectedObjekts.length > 0 ? (
          <motion.div
            className='fixed inset-x-6 bottom-4 max-w-lg mx-auto'
            variants={TRANSITION_VARIANTS.scaleSpringOpacity}
            initial='initial'
            animate='enter'
            exit='exit'
          >
            <SelectedObjektsCard isMyAccount={isMyAccount} />
          </motion.div>
        ) : null}
      </AnimatePresence>
    </div>
  );
}

export async function objektsTabLoader() {
  await Promise.all([
    queryClient.prefetchQuery(allObjektsQuery),
    queryClient.prefetchQuery(artistQuery('tripleS')),
    queryClient.prefetchQuery(artistQuery('artms')),
  ]);
  return null;
}

export function ObjektsTabRoute() {
  let { userId } = useParams() as { userId: string };
  let { address } = useOutletContext() as { address: string };
  let { data: objekts } = useSuspenseQuery(allObjektsQuery);
  let { data: tripleS } = useSuspenseQuery(artistQuery('tripleS'));
  let { data: artms } = useSuspenseQuery(artistQuery('artms'));

  return (
    <ObjektsTab
      objekts={objekts}
      artists={[tripleS, artms]}
      address={address}
      userId={userId}
    />
  );
}

export function TradesTabRoute() {
  let { address } = useOutletContext() as { address: string };
  if (!address) {
    return null;
  }
  return <TradesTab address={address} />;
}

export default function Objekts() {
  let { userId } = useParams() as { userId?: string };
  let { address, profileImageUrl } = useLoaderData() as Awaited<
    ReturnType<typeof loader>
  >;
  let isUserTradeTab = !!useMatch(':userId/trades');
  let isMyTradeTab = !!useMatch('trades');
  let isTradeTab = isUserTradeTab || isMyTradeTab;
  let { isLoading } = useAccounts();
  let currentAccount = useCurrentAccount();
  if (!address && currentAccount) {
    profileImageUrl = currentAccount.profile.profileImageUrl;
    if (currentAccount.profile.profile.length > 0) {
      profileImageUrl = currentAccount.profile.profile[0].image.original;
    }
    address = currentAccount.profile.address;
  }
  let { data: tripleSComo, isLoading: isTripleSComoLoading } = useQuery({
    ...comoQuery('tripleS', address!),
    enabled: !!address,
  });
  let { data: artmsComo, isLoading: isArtmsComoLoading } = useQuery({
    ...comoQuery('artms', address!),
    enabled: !!address,
  });
  let isComoLoading = !address || isTripleSComoLoading || isArtmsComoLoading;

  return (
    <div className='max-w-5xl mx-auto px-6 py-2 flex flex-col gap-4'>
      <div className='flex flex-col sm:relative'>
        {userId || currentAccount || isLoading ? (
          <>
            <User
              // as={!userId && isLoading ? Skeleton : undefined}
              name={
                <Skeleton
                  isLoaded={!!userId || !isLoading}
                  className='rounded-lg'
                >
                  {userId ??
                    (isLoading ? 'nickname' : currentAccount?.profile.nickname)}
                </Skeleton>
              }
              description={
                <Skeleton
                  isLoaded={!!userId || !isLoading}
                  className='rounded-lg'
                >
                  {userId?.startsWith('@')
                    ? address
                    : !userId
                      ? isLoading
                        ? '0x0000000000000000000000000000000000000000'
                        : currentAccount?.profile.address
                      : undefined}
                </Skeleton>
              }
              avatarProps={{
                as: !userId && isLoading ? Skeleton : undefined,
                src:
                  profileImageUrl ||
                  'https://static.cosmo.fans/uploads/images/img_profile_gallag@3x.png',
                className: 'h-20 w-20 shrink-0',
              }}
              classNames={{
                base: 'justify-start my-4',
                name: 'text-large',
                wrapper: 'min-w-0',
                description: 'text-base w-full overflow-hidden text-ellipsis',
              }}
            />
            <div className='flex gap-2 sm:absolute sm:end-0 sm:top-1/2 sm:-translate-y-1/2'>
              <Skeleton
                isLoaded={!!userId || !isComoLoading}
                className='rounded-full'
              >
                <Chip
                  classNames={{ content: 'pe-1' }}
                  startContent={
                    <ComoTripleSIcon className='w-4 h-4 ms-[2px]' />
                  }
                >
                  {`${tripleSComo ?? 0n}`}
                </Chip>
              </Skeleton>
              <Skeleton
                isLoaded={!!userId || !isComoLoading}
                className='rounded-full'
              >
                <Chip
                  classNames={{ content: 'pe-1' }}
                  startContent={<ComoArtmsIcon className='w-5 h-5 ms-px' />}
                >
                  {`${artmsComo ?? 0n}`}
                </Chip>
              </Skeleton>
            </div>
          </>
        ) : null}
      </div>
      <Tabs
        classNames={{ panel: 'p-0' }}
        selectedKey={isTradeTab ? 'trades' : 'objekts'}
      >
        <Tab key='objekts' title={<NavLink to=''>Objekts</NavLink>}>
          <Outlet context={{ address }} />
        </Tab>
        <Tab key='trades' title={<NavLink to='trades'>Trades</NavLink>}>
          <Outlet context={{ address }} />
        </Tab>
      </Tabs>
    </div>
  );
}
