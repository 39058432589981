import { create } from 'zustand';

import { Objekt } from '../cosmo';

type SendObjektsStore = {
  selectedObjekts: Objekt[];
  txStates: {
    status: 'initial' | 'sending' | 'success' | 'error';
    error: string;
  }[];
  isLoading: boolean;
  error: string;
  actions: {
    setSelectedObjekts: (selectedObjekts: Objekt[]) => void;
    selectObjekt: (objekt: Objekt) => void;
    unselectObjekt: (objekt: Objekt) => void;
    clearSelectedObjekts: () => void;
    setTxState: (
      index: number,
      status: 'initial' | 'sending' | 'success' | 'error',
      error: string,
    ) => void;
    setIsLoading: (isLoading: boolean) => void;
    setError: (error: string) => void;
  };
};

export let useSendObjektsStore = create<SendObjektsStore>(set => ({
  selectedObjekts: [],
  txStates: [],
  isLoading: false,
  error: '',
  actions: {
    setSelectedObjekts(selectedObjekts: Objekt[]) {
      set(({ isLoading }) =>
        isLoading
          ? {}
          : {
              selectedObjekts,
              txStates: selectedObjekts.map(() => ({
                status: 'initial',
                error: '',
              })),
            },
      );
    },
    selectObjekt(objekt: Objekt) {
      set(({ selectedObjekts, txStates, isLoading }) =>
        isLoading
          ? {}
          : {
              selectedObjekts: [...selectedObjekts, objekt],
              txStates: [...txStates, { status: 'initial', error: '' }],
            },
      );
    },
    unselectObjekt(objekt: Objekt) {
      set(({ selectedObjekts, txStates, isLoading }) =>
        isLoading
          ? {}
          : {
              selectedObjekts: selectedObjekts.filter(o => o !== objekt),
              txStates: txStates.filter(
                (_, i) => i !== selectedObjekts.indexOf(objekt),
              ),
            },
      );
    },
    clearSelectedObjekts() {
      set({ selectedObjekts: [], txStates: [], isLoading: false, error: '' });
    },
    setTxState(
      index: number,
      status: 'initial' | 'sending' | 'success' | 'error',
      error: string,
    ) {
      set(({ txStates }) => ({
        txStates: [
          ...txStates.slice(0, index),
          { status, error },
          ...txStates.slice(index + 1),
        ],
      }));
    },
    setIsLoading(isLoading: boolean) {
      set({ isLoading });
    },
    setError(error: string) {
      set({ error });
    },
  },
}));

export let useSelectedObjekts = () =>
  useSendObjektsStore(state => state.selectedObjekts);
export let useTxStates = () => useSendObjektsStore(state => state.txStates);
export let useIsLoading = () => useSendObjektsStore(state => state.isLoading);
export let useSendError = () => useSendObjektsStore(state => state.error);
export let useSendObjektsActions = () =>
  useSendObjektsStore(state => state.actions);
