import { Objekt, Profile } from './cosmo';
import { getShortCollectionId } from './utils';

export type Transfer = {
  from: string;
  to: string;
  blockNumber: number;
};

export type List = {
  name: string;
  collectionIds: string[];
};

export class PolarisClient {
  base: URL;
  token: string | null;

  constructor(base: URL | string = 'https://api.cosmo.goranmoomin.dev') {
    if (typeof base === 'string') {
      base = new URL(base);
    }
    this.base = base;
    this.token = null;
  }

  async get<T>(url: URL | string): Promise<T> {
    let response = await fetch(new URL(url, this.base), {
      method: 'GET',
      headers: {
        ...(this.token && { Authorization: this.token }),
      },
    });
    if (!response.ok) {
      let message = response.statusText;
      throw new Error(message);
    }
    let json = await response.json();
    return json;
  }

  async post<T>(url: URL | string, body: any): Promise<T> {
    let response = await fetch(new URL(url, this.base), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...(this.token && { Authorization: this.token }),
      },
      body: JSON.stringify(body),
    });
    if (!response.ok) {
      let message = response.statusText;
      throw new Error(message);
    }
    let json = await response.json();
    return json;
  }

  async put<T>(url: URL | string, body: any): Promise<T> {
    let response = await fetch(new URL(url, this.base), {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        ...(this.token && { Authorization: this.token }),
      },
      body: JSON.stringify(body),
    });
    if (!response.ok) {
      let message = response.statusText;
      throw new Error(message);
    }
    let json = await response.json();
    return json;
  }

  async delete<T>(url: URL | string, body: any): Promise<T> {
    let response = await fetch(new URL(url, this.base), {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        ...(this.token && { Authorization: this.token }),
      },
      body: JSON.stringify(body),
    });
    if (!response.ok) {
      let message = response.statusText;
      throw new Error(message);
    }
    let json = await response.json();
    return json;
  }

  async getAllObjekts(): Promise<Objekt[]> {
    let json = await fetch('https://cosmo.goranmoomin.dev/objekts.json').then(
      response => response.json(),
    );
    let objekts = (json as any[])
      .filter(item => 'objekt' in item)
      // FIXME: This is a hack, a dirty hack, a dirty, dirty hack.
      // This is just to make search work without having to change anything else.
      .map(item => ({
        ...item.objekt,
        shortCollectionId: getShortCollectionId(item.objekt as Objekt),
      }));
    return objekts;
  }

  async getCollectionDetails(collectionId: string): Promise<{
    count: number;
    owners: [number, string][];
  }> {
    let encodedCollectionId = encodeURIComponent(collectionId);
    let { count, owners } = await this.get<{
      count: number;
      owners: [number, string][];
    }>(`/objekt/${encodedCollectionId}`);
    return { count, owners };
  }

  async getObjektCount(collectionId: string): Promise<number> {
    let encodedCollectionId = encodeURIComponent(collectionId);
    let { count } = await this.get<{ count: number }>(
      `/objekt/${encodedCollectionId}`,
    );
    return count;
  }

  async getObjektDetails(
    collectionId: string,
    objektNo: number,
  ): Promise<{
    tokenId: number;
    transfers: { from: string; to: string; blockNumber: number }[];
  }> {
    let encodedCollectionId = encodeURIComponent(collectionId);
    let { tokenId, transfers } = await this.get<{
      tokenId: number;
      transfers: { from: string; to: string; blockNumber: number }[];
    }>(`/objekt/${encodedCollectionId}/${objektNo}`);
    return { tokenId, transfers };
  }

  async getObjektTransfers(
    collectionId: string,
    objektNo: number,
  ): Promise<{ from: string; to: string; blockNumber: number }[]> {
    let encodedCollectionId = encodeURIComponent(collectionId);
    let { transfers } = await this.get<{
      transfers: {
        from: string;
        to: string;
        blockNumber: number;
      }[];
    }>(`/objekt/${encodedCollectionId}/${objektNo}`);
    return transfers;
  }

  async signIn(socialLoginUserId: string): Promise<Profile> {
    let { profile } = await this.post<{ profile: Profile }>('/user', {
      id: socialLoginUserId,
    });
    return profile;
  }

  async getUserDetails(address: string): Promise<{
    transfers: (Transfer & { objekt: Objekt })[];
    lists: List[];
  }> {
    return this.get<{
      transfers: (Transfer & { objekt: Objekt })[];
      lists: List[];
    }>(`/user/${address}/`);
  }

  async addCollectionsToList(
    listName: string,
    collectionIds: string[],
  ): Promise<void> {
    await this.put<{ collectionIds: string[] }>(`/user/me/${listName}`, {
      collectionIds,
    });
  }

  async removeCollectionsFromList(
    listName: string,
    collectionIds: string[],
  ): Promise<void> {
    await this.delete<{ collectionIds: string[] }>(`/user/me/${listName}`, {
      collectionIds,
    });
  }
}

export let polarisClient = new PolarisClient();

// @ts-ignore
globalThis.polarisClient = polarisClient;
