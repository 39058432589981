import { DependencyList, useState, useEffect } from 'react';
import { Paginated, Objekt } from './cosmo';

export function getShortCollectionId(objekt: Objekt) {
  return `${objekt.member} ${objekt.season.slice(0, 1)}${objekt.collectionNo}`;
}

export function usePaginatedObjekts(
  getPage: (
    startAfter?: number,
  ) => Promise<Paginated<{ total: number; objekts: Objekt[] }>>,
  deps: DependencyList,
): Objekt[] {
  let [objekts, setObjekts] = useState<Objekt[]>([]);
  useEffect(() => {
    setObjekts([]);
    let isCancelled = false;
    (async () => {
      let page = await getPage();
      if (!isCancelled) {
        setObjekts(objekts => [...objekts, ...page.objekts]);
      }
      if (page.hasNext) {
        let nextStartAfter = parseInt(page.nextStartAfter);
        let promises: Promise<void>[] = [];
        for (
          let startAfter = nextStartAfter;
          startAfter < page.total;
          startAfter += nextStartAfter
        ) {
          promises.push(
            getPage(startAfter).then(page => {
              if (!isCancelled) {
                setObjekts(objekts => [...objekts, ...page.objekts]);
              }
            }),
          );
        }
        await Promise.all(promises);
      }
    })();
    return () => void (isCancelled = true);
  }, deps);
  return objekts;
}

export function filterObjekts(
  objekts: Objekt[],
  members: string[],
  seasons: string[],
  classes: string[],
  filterOption: 'all' | 'owned' | 'transferable',
  ownedObjekts: Objekt[],
) {
  objekts = objekts
    .filter(objekt => (members.length ? members.includes(objekt.member) : true))
    .filter(objekt => (seasons.length ? seasons.includes(objekt.season) : true))
    .filter(objekt => (classes.length ? classes.includes(objekt.class) : true));
  switch (filterOption) {
    case 'owned':
      objekts = objekts.filter(objekt =>
        ownedObjekts.some(
          ownedObjekt => ownedObjekt.collectionId === objekt.collectionId,
        ),
      );
      break;
    case 'transferable':
      objekts = objekts.filter(objekt =>
        ownedObjekts.some(
          ownedObjekt =>
            ownedObjekt.collectionId === objekt.collectionId &&
            ownedObjekt.transferable,
        ),
      );
      break;
    case 'all':
      break;
  }
  return objekts;
}
